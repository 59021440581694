<template>

    <a-spin :spinning="confirmLoading" style="width: 1000px;padding-top: 50px">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="用戶ID">
          <a-input :maxlength="10" placeholder="请输入用戶刷手號（如：6000001）" v-decorator="['userNum',validatorRules.userNum]" />
        </a-form-item>

        <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="是否可下载">
          <a-select v-decorator="['download',validatorRules.download]">
            <a-select-option value="0">不可以</a-select-option>
            <a-select-option value="1">可以</a-select-option>
          </a-select>
        </a-form-item>
<!--
        <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="文件目录">
          <a-input :maxlength="10" placeholder="请输入文件目录（如：D://admin/app）" v-decorator="['fileUrl',validatorRules.fileUrl]" />
        </a-form-item>-->

        <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="最大序号">
          <a-input-number placeholder="请输入最大序号" v-decorator="['maxNum',validatorRules.maxNum]" />
        </a-form-item>

        <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="选择文件">
            <input type="file" multiple="multiple" @change="handleFileChange" ref="fileData"/>
<!--            <p style="color: red">注意：请选择上述文件目录下的所有文件</p>-->
        </a-form-item>

      </a-form>
      <a-form-item  style="margin-top:24px;padding-left: 200px">
        <a-button v-if="videoNum == 0" style="margin-left:280px" type="primary" icon="reload" @click="dataVer">数据校验</a-button>
        <a-button v-if="videoNum > 0 && thisNum == 0" style="margin-left:280px" type="primary" icon="to-top" @click="uploadData">开始上传</a-button>
        <a-button v-if="visible && videoNum > 0 && videoNum == thisNum" style="margin-left:280px" type="primary" icon="arrow-up" @click="saveData">提交数据</a-button>
        <a-button v-if="!visible" style="margin-left:280px" type="primary" icon="redo" @click="resetData">重置数据</a-button>
      </a-form-item>

      <a-form-item  style="margin-top:24px;padding-left: 280px" v-if="videoNum != 0">
<!--        <p v-if="isProgress" style="color: red">当前上传进度{{progress}}%</p>-->
        <p style="color: #00bb00;font-size: 20px">总上传进度（{{thisNum}}/{{videoNum}}）</p>
      </a-form-item>
      <a-form-item v-if="show" style="margin-top:12px;padding-left: 280px">
        <p style="line-height: 20px;font-size: 18px;color: #00a0e9">数据提交成功。业务完成！！！</p>
      </a-form-item>
      <a-form-item  style="margin-top:12px;padding-left: 280px" v-if="progressList != null && progressList.length > 0">
        <p v-for="progressData in progressList" style="line-height: 20px;font-size: 18px">{{progressData}}  上传成功！</p>
      </a-form-item>
    </a-spin>
</template>

<script>
  import ObsClient from "esdk-obs-browserjs";
  import request from "@/utils/request";

  import axios from 'axios'
  import { getOssConfig } from '@/api/system/upload.js'
  import storage from 'store'
  import { ACCESS_TOKEN } from '@/store/mutation-types'

  export default {
    name: "VideoUpload",
    data () {
      return {
        title:"操作",
        visible: true,
        files:[],
        textFile:[],
        coverList:[],
        videoList:[],
        model: {},
        thisNum: 0,
        videoNum: 0,
        show: false,
        data: [],
        progress:0,
        progressList: [
        ],
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },

        confirmLoading: false,
        form: this.$form.createForm(this),
        validatorRules:{
          userNum:{rules: [{required: true, message: '请輸入用戶CODE!'}]},
          accessControl:{rules: [{required: true, message: '请设置权限!'}]},
          download:{rules: [{required: true, message: '请选择是否可下载!'}]},
          maxNum:{rules: [{required: true, message: '请输入最大序号!'}]},
        //  fileUrl:{rules: [{required: true, message: '请输入文件目录!'}]},
        },
        url: {
          dataVer: "/backstage/videoUpload/videoDataVer",
          save: "/backstage/videoUpload/save",
          queryByConfigGroup:"/config/config/queryByConfigGroup",
        },
      }
    },
    created () {
    },
    methods: {
      dataVer(){  //数据验证
        let that = this;
        that.form.validateFields((err, values) => {
          let formData = Object.assign(this.model, values);
         // formData.fileData = that.files;
          let thisFiles = that.files;
          let fileNames = [];
          for (let i = 0; i < thisFiles.length; i++) {
             const file = thisFiles[i];
             fileNames.push(file.name);
          }
          formData.textFiles = JSON.stringify(that.textFile);
          formData.fileNames = JSON.stringify(fileNames);

          request({
            url: that.url.dataVer,
            method: 'post',
            data: formData
          }).then(res => {
            if(res.success){
              that.$message.success("校验通过");
              that.data = res.data;
              that.videoNum = res.data.length * 2;
            }else{
              that.$message.warning(res.message);
            }
          })
        })
      },
      readFile(file) {
        let that = this;
        var reader = new FileReader();
        reader.onload = function(event) {
          console.log("result:"+event.target.result);
          let data = {};
          data.name = file.name;
          data.text = event.target.result;
          that.textFile.push(data);
        };
        reader.readAsText(file);
      },
      uploadData () {    //上传数据
        let that = this;
        let thisFiles = that.files;
        let data = that.data;
        for(let ii = 0;ii<data.length;ii++){
          for (let i = 0; i < thisFiles.length; i++) {
            const file = thisFiles[i];
            if(data[ii].cover == file.name){
              that.uploadFile(data[ii].id,1, file);
            }
            if(data[ii].video == file.name){
              that.uploadFile(data[ii].id,2, file);
              that.sleep(100);
            }
            console.log('文件名：', file.name);
            console.log('文件大小：', file.size);
            console.log('文件類型：', file.type);
          }
        }
      },
      sleep(ms) {
         return new Promise(resolve =>setTimeout(resolve, ms));
      },
      saveData(){    //提交数据
        let that = this;
        let data = that.data;
        let videoList = that.videoList;
        let coverList = that.coverList;
        for(let i = 0; i < data.length; i ++){
           for(let ii = 0 ; ii < coverList.length; ii++){
               if(data[i].id == coverList[ii].id){
                 data[i].cover = coverList[ii].data;
               }
           }
          for(let ii = 0 ; ii < videoList.length; ii++){
            if(data[i].id == videoList[ii].id){
              data[i].video = videoList[ii].data;
            }
          }
        }
        request({
          url: that.url.save,
          method: 'post',
          data: data
        }).then(res => {
          if(res.success){
            that.$message.success("保存成功");
            that.visible = false;
          }else{
            that.$message.warning(res.message);
          }
        })
      },
      uploadFile(id,type,file) {
        let that = this;
        const token = storage.get(ACCESS_TOKEN)
        // 随机生成一个文件名，并且按照日期归类到二级目录下，否则很容易产生覆盖
        const dates = new Date()
        const top = "backstage"
        const year = dates.getFullYear()
        const month = dates.getMonth() + 1
        const date = dates.getDate()
        const nowDate = year + '-' + (month < 10 ? `0${month}` : month) + '-' + (date < 10 ? `0${date}` : date)
        const bmonth = month < 10 ? `0${month}` : month
        const bdate = date < 10 ? `0${date}` : date
        var last = "";
        if(type == 1){
          last = "jpg"
        }else{
          last = "mp4"
        }
        getOssConfig().then(res => {
          let ossData = res.data
          let form = new FormData()
          console.log("this.files[0].name:",this.files)
         // let name = this.files[0].name.split("\.")[1]
          form.append('key', `${top}/${year}/${bmonth}/${bdate}/` + ossData.fileName + '.' + last)
          form.append('policy', ossData.policy)
          form.append('OSSAccessKeyId', ossData.accessid)
          form.append('success_action_status', '200')
          form.append('signature', ossData.signature)
          form.append('file', file)
          console.log("form=========>"+JSON.stringify(form));
          axios({
            url: ossData.uploadUrl,
            method: 'post',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': 'attachment;filename=' + encodeURIComponent(ossData.fileName)
            },
            data: form,
          }).then(res => {
            console.log('res', res)
            if (res.status === 200) {
              let videoUrl = ossData.resultUrl + `/${top}/${year}/${bmonth}/${bdate}/` + ossData.fileName + '.' + last
              console.log("videoUrl=========>"+videoUrl);
              let video = {};
              video.id = id;
              video.data = videoUrl;
              if(type == 1){
                that.coverList.push(video);
              }else{
                that.videoList.push(video);
              }
              that.thisNum = that.thisNum + 1;

              let thisProgress = [];
              thisProgress.push(file.name+" "+(type == 1 ? "封面":"視頻"));
              let progressList = that.progressList;
              for (let i = 0 ; i < progressList.length && i < 9;i++){
                thisProgress.push(progressList[i]);
              }
              that.progressList = thisProgress;

            } else {
              that.$message.warning('上传失败，请重试');
            }
          }).finally(res => {
            console.log('结束', res)
          })
        })
      },
      generateUUID() {
        let d = new Date().getTime();    // 生成時間戳
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          let r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d/16);
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
      },
      handleFileChange(event) {//选择文件
        const files = event.target.files;
        let that = this;
        that.files = files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          let reg = /txt$/;
          if (reg.test(file.name)) {
            that.readFile(file);
          }
        }

      },
      resetData(){//重置数据
        this.form.resetFields();
        this.visible = true;
        this.files = [];
        this.coverList = [];
        this.videoList = [];
        this.model =  {};
        this.thisNum =  0;
        this.videoNum =  0;
        this.show =  false;
        this.data =  [];
        this.textFile = [];
        this.progress = 0;
        this.progressList =  [];
        this.$refs.fileData.value = null;
        this.$message.success("重置成功！");
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
